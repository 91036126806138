import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateMoviecert(props) {
  const [cert_name, setCertName] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!cert_name) {
      errors.cert_name = "Certificate name is required";
    }
    return errors;
  };

  const addCertificate = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const certficate_name = cert_name;

      const api_url = localStorage.getItem('api_url');
      const response = await axios.post(api_url + '/addMovieCert', {
        certficate_name
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status) {
        
     
          alert(response.data.message);
          navigate("/Moviecert", { replace: true });
      }
  };

  useEffect(() => {
    // You can use this effect to fetch existing certificates if needed.
  }, []); 

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Certificates</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Certificate Name</p>
              <button className="itme_img11" onClick={addCertificate}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={cert_name}
              onChange={(e) => setCertName(e.target.value)}
            />
            {errors.cert_name && <Alert severity="error">{errors.cert_name}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateMoviecert);
