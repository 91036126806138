import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "./withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Header from "./Header";
import Footer from "./Footer";
import SidebarOne from "./sidebar1";
import InnerHeader from "./InnerHeader";
import DatatableHalls from "./datatableHalls";

class Halls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: ''
    };
    
  }

  componentDidMount() {
    
  }


  render() {
   
    return (
      <div className="productsa">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div>
          
          <DatatableHalls />
        </div>
        
        
      </div>
    );
  }
}

export default withRouter(Halls);
