import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateShowtimings(props) {
  const [screenId, setScreenId] = useState("");
  const [movieId, setMovieId] = useState("");
  const [showTime, setShowTime] = useState("");
  const [formDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [errors, setErrors] = useState({});
  const [screens, setScreens] = useState([]);
  const [movies, setMovies] = useState([]);
  const navigate = useNavigate();
  const admin_id = localStorage.getItem('admin_id');

  const api_url = localStorage.getItem('api_url');

  useEffect(() => {
    // Fetch screens and movies for dropdowns
    const fetchData = async () => {
      try {
        const res = await axios.get(api_url + '/getScreens/'+admin_id);
        const movieRes = await axios.get(`${api_url}/getMovies`);
        setScreens(res.data.screen_details);
        setMovies(movieRes.data.moviedetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [api_url]);

  const validateInputs = () => {
    const errors = {};
    if (!screenId) errors.screenId = "Screen ID is required";
    if (!movieId) errors.movieId = "Movie ID is required";
    if (!showTime) errors.showTime = "Show time is required";
    if (!formDate) errors.formDate = "Form date is required";
    if (!toDate) errors.toDate = "To date is required";
    return errors;
  };

  const addShowTiming = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      screen_id: screenId,
      movie_id: movieId,
      show_time: showTime,
      from_date: formDate,
      to_date: toDate,
      theatre_id:admin_id
    };

    axios({
      method: "post",
      url: `${api_url}/savetimeslots`,
      data,
    })
      .then((res) => {
        if (res.data.status === "true") {
          alert(res.data.message);
          navigate("/Showtimings", { replace: true });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding show timing:", error);
        alert("Failed to add show timing.");
      });
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Showtimings</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
          <div style={{ display: 'flex' }}>
              <p className="item_name">Screen Name</p>
            </div>
            <select value={screenId}  className="item_input_cat" onChange={(e) => setScreenId(e.target.value)}>
              <option value="">Select Screen</option>
              {screens.map((screen) => (
                <option key={screen._id} value={screen._id}>
                  {screen.screen_name}
                </option>
              ))}
            </select>
            {errors.screenId && <Alert severity="error">{errors.screenId}</Alert>}
          </div>

          <div className="item_details">
          <div style={{ display: 'flex' }}>
              <p className="item_name">Movie Name</p>
            </div>
            <select value={movieId} className="item_input_cat" onChange={(e) => setMovieId(e.target.value)}>
              <option value="">Select Movie</option>
              {movies.map((movie) => (
                <option key={movie.id} value={movie.id}>
                  {movie.movie_name}
                </option>
              ))}
            </select>
            {errors.movieId && <Alert severity="error">{errors.movieId}</Alert>}
          </div>

          <div className="item_details">
          <div style={{ display: 'flex' }}>
              <p className="item_name">Show Time</p>
            </div>
            <input
              type="time"
              className="item_input_cat"
              value={showTime}
              onChange={(e) => setShowTime(e.target.value)}
            />
            {errors.showTime && <Alert severity="error">{errors.showTime}</Alert>}
          </div>

          <div className="item_details">
          <div style={{ display: 'flex' }}>
              <p className="item_name">From Date</p>
            </div>
            <input
              type="date"
              className="item_input_cat"
              value={formDate}
              onChange={(e) => setFormDate(e.target.value)}
            />
            {errors.formDate && <Alert severity="error">{errors.formDate}</Alert>}
          </div>

          <div className="item_details">
          <div style={{ display: 'flex' }}>
              <p className="item_name">To Date</p>
            </div>
            <input
              type="date"
              className="item_input_cat"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            {errors.toDate && <Alert severity="error">{errors.toDate}</Alert>}
          </div>
          <div className="item_details" style={{ textAlign: 'right' }}>
            <button type="button" className="itme_img11" onClick={addShowTiming}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateShowtimings);
