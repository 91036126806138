import React, { useState } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';


function CreateScreen() {
  const [screenName, setScreenName] = useState("");
  const [screenType, setScreenType] = useState("");
  const navigate = useNavigate();

  const [containers, setContainers] = useState([{
    container_name: "",
    price: "",
    rows: [
      {
        prefix: "A", // Default prefix for the first row
        startNumber: 1,
        endNumber: 10,
        breakAfter: "", // Comma-separated seat numbers for breaks
        seats: []
      }
    ]
  }]);
  
  const [errors, setErrors] = useState({});

  // Handle screen name and type changes
  const handleScreenNameChange = (e) => setScreenName(e.target.value);
  const handleScreenTypeChange = (e) => setScreenType(e.target.value);

  // Handle container data changes
  const handleContainerChange = (containerIndex, e) => {
    const { name, value } = e.target;
    setContainers(prevContainers => 
      prevContainers.map((container, index) =>
        index === containerIndex ? { ...container, [name]: value } : container
      )
    );
  };

  // Handle row data changes
  const handleRowChange = (containerIndex, rowIndex, e) => {
    const { name, value } = e.target;
    setContainers(prevContainers =>
      prevContainers.map((container, cIndex) => {
        if (cIndex === containerIndex) {
          const updatedRows = container.rows.map((row, rIndex) => 
            rIndex === rowIndex ? { ...row, [name]: value } : row
          );
          return { ...container, rows: updatedRows };
        }
        return container;
      })
    );
  };

  // Add a new row to a container
  const addRow = (containerIndex) => {
    setContainers(prevContainers =>
      prevContainers.map((container, index) =>
        index === containerIndex 
          ? { 
              ...container, 
              rows: [
                ...container.rows, 
                { 
                  prefix: String.fromCharCode(65 + container.rows.length), // Next letter (A, B, C, ...)
                  startNumber: 1,
                  endNumber: 10,
                  breakAfter: "",
                  seats: []
                }
              ] 
            } 
          : container
      )
    );
  };

  // Add a new container
  const addContainer = () => {
    setContainers([...containers, {
      container_name: "",
      price: "",
      rows: [
        {
          prefix: "A",
          startNumber: 1,
          endNumber: 10,
          breakAfter: "",
          seats: []
        }
      ]
    }]); 
  };

  // Generate seats for a container
  const generateSeats = (containerIndex) => {
    const container = containers[containerIndex];
    const updatedRows = container.rows.map(row => {
      const seatsArray = [];
      for (let i = row.startNumber; i <= row.endNumber; i++) {
        const seatNo = `${row.prefix}${i}`;
        seatsArray.push(seatNo);
        if (row.breakAfter) {
          const breaks = row.breakAfter.split(',').map(num => num.trim());
          if (breaks.includes(i.toString())) {
            seatsArray.push(`${row.prefix}-break`);
          }
        }
      }
      return { ...row, seats: seatsArray };
    });

    setContainers(prevContainers =>
      prevContainers.map((container, index) =>
        index === containerIndex ? { ...container, rows: updatedRows } : container
      )
    );
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const api_url = localStorage.getItem('api_url');
    const theatre_id = localStorage.getItem('theatre_id');
    const formData = {
      theatre_id,
      screen_name: screenName,
      screen_type: screenType,
      containers,
    };

    try {
      await axios.post(`${api_url}/createscreens`, formData);
      alert("Containers and seats created successfully!");
      navigate("/Screens", { replace: true });

      // Optionally reset form here
    } catch (error) {
      console.error("Error creating containers and seats:", error);
      alert("Error creating containers and seats. Please try again.");
    }
  };

  // Validate inputs
  const validateInputs = () => {
    let formErrors = {};
    if (!screenName.trim()) formErrors.screen_name = "Screen Name is required";
    if (!screenType.trim()) formErrors.screen_type = "Screen Type is required";

    containers.forEach((container, cIndex) => {
      let containerErrors = {};
      if (!container.container_name.trim()) containerErrors.container_name = "Container Name is required";
      if (!container.price.trim()) containerErrors.price = "Price is required";

      container.rows.forEach((row, rIndex) => {
        if (!row.prefix.trim()) {
          containerErrors[`container_${cIndex}_row_${rIndex}_prefix`] = "Seat Prefix is required";
        }
        if (!row.startNumber || isNaN(row.startNumber)) {
          containerErrors[`container_${cIndex}_row_${rIndex}_startNumber`] = "Start Number is required and should be a number";
        }
        if (!row.endNumber || isNaN(row.endNumber)) {
          containerErrors[`container_${cIndex}_row_${rIndex}_endNumber`] = "End Number is required and should be a number";
        }
        if (parseInt(row.startNumber) > parseInt(row.endNumber)) {
          containerErrors[`container_${cIndex}_row_${rIndex}_numberOrder`] = "Start Number cannot be greater than End Number";
        }
        // breakAfter is optional, but if provided, should be comma-separated numbers
        if (row.breakAfter) {
          const breaks = row.breakAfter.split(',').map(num => num.trim());
          const invalidBreaks = breaks.filter(num => isNaN(num) || parseInt(num) < row.startNumber || parseInt(num) > row.endNumber);
          if (invalidBreaks.length > 0) {
            containerErrors[`container_${cIndex}_row_${rIndex}_breakAfter`] = `Invalid break seat numbers: ${invalidBreaks.join(', ')}`;
          }
        }
      });

      if (Object.keys(containerErrors).length > 0) {
        formErrors[`container_${cIndex}`] = containerErrors;
      }
    });

    return formErrors;
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="container-wrapper">
        <h2>Create Screen Structure</h2>
        <form onSubmit={handleSubmit} className="form">
          {/* Screen Name */}
          <div className="form-group">
            <label>Screen Name</label>
            <input
              type="text"
              name="screen_name"
              value={screenName}
              onChange={handleScreenNameChange}
            />
            {errors.screen_name && <Alert severity="error">{errors.screen_name}</Alert>}
          </div>
          
          {/* Screen Type */}
          <div className="form-group">
            <label>Screen Type</label>
            <input
              type="text"
              name="screen_type"
              value={screenType}
              onChange={handleScreenTypeChange}
            />
            {errors.screen_type && <Alert severity="error">{errors.screen_type}</Alert>}
          </div>
          
          {/* Containers */}
          {containers.map((container, containerIndex) => (
            <div key={containerIndex} className="container-form">
              <h3>Container {containerIndex + 1}</h3>
              
              {/* Container Name */}
              <div className="form-group">
                <label>Container Name</label>
                <input
                  type="text"
                  name="container_name"
                  value={container.container_name}
                  onChange={(e) => handleContainerChange(containerIndex, e)}
                />
                {errors[`container_${containerIndex}`]?.container_name && <Alert severity="error">{errors[`container_${containerIndex}`].container_name}</Alert>}
              </div>
              
              {/* Price */}
              <div className="form-group">
                <label>Price</label>
                <input
                  type="text"
                  name="price"
                  value={container.price}
                  onChange={(e) => handleContainerChange(containerIndex, e)}
                />
                {errors[`container_${containerIndex}`]?.price && <Alert severity="error">{errors[`container_${containerIndex}`].price}</Alert>}
              </div>
              

              

              {/* Seat Configuration */}
              <h4>Seats Configuration</h4>
              
              {/* Rows */}
              {container.rows.map((row, rowIndex) => (
                <div key={rowIndex} className="row-form" style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                  <h5>Seat Rows</h5>
                  
                  {/* Seat Prefix */}
                  <div className="form-group">
                    <label>Seat Prefix</label>
                    <input
                      type="text"
                      name="prefix"
                      value={row.prefix}
                      onChange={(e) => handleRowChange(containerIndex, rowIndex, e)}
                    />
                    {errors[`container_${containerIndex}`]?.[`container_${containerIndex}_row_${rowIndex}_prefix`] && (
                      <Alert severity="error">{errors[`container_${containerIndex}`][`container_${containerIndex}_row_${rowIndex}_prefix`]}</Alert>
                    )}
                  </div>
                  
                  {/* Start Number */}
                  <div className="form-group">
                    <label>Start Number</label>
                    <input
                      type="number"
                      name="startNumber"
                      value={row.startNumber}
                      onChange={(e) => handleRowChange(containerIndex, rowIndex, e)}
                    />
                    {errors[`container_${containerIndex}`]?.[`container_${containerIndex}_row_${rowIndex}_startNumber`] && (
                      <Alert severity="error">{errors[`container_${containerIndex}`][`container_${containerIndex}_row_${rowIndex}_startNumber`]}</Alert>
                    )}
                  </div>
                  
                  {/* End Number */}
                  <div className="form-group">
                    <label>End Number</label>
                    <input
                      type="number"
                      name="endNumber"
                      value={row.endNumber}
                      onChange={(e) => handleRowChange(containerIndex, rowIndex, e)}
                    />
                    {errors[`container_${containerIndex}`]?.[`container_${containerIndex}_row_${rowIndex}_endNumber`] && (
                      <Alert severity="error">{errors[`container_${containerIndex}`][`container_${containerIndex}_row_${rowIndex}_endNumber`]}</Alert>
                    )}
                    {errors[`container_${containerIndex}`]?.[`container_${containerIndex}_row_${rowIndex}_numberOrder`] && (
                      <Alert severity="error">{errors[`container_${containerIndex}`][`container_${containerIndex}_row_${rowIndex}_numberOrder`]}</Alert>
                    )}
                  </div>
                  
                  {/* Break After Seat No */}
                  <div className="form-group">
                    <label>Break After Seat No</label>
                    <input
                      type="text"
                      name="breakAfter"
                      value={row.breakAfter}
                      onChange={(e) => handleRowChange(containerIndex, rowIndex, e)}
                      placeholder="Optional (e.g., 5,10)"
                    />
                    {errors[`container_${containerIndex}`]?.[`container_${containerIndex}_row_${rowIndex}_breakAfter`] && (
                      <Alert severity="error">{errors[`container_${containerIndex}`][`container_${containerIndex}_row_${rowIndex}_breakAfter`]}</Alert>
                    )}
                  </div>
                </div>
              ))}
              
              {/* Add More Rows Button */}
              <button type="button" className="btn btn-primary" onClick={() => addRow(containerIndex)}>Add More Rows</button>
              
              {/* Generate Seats Button */}
              <button type="button" className="btn btn-primary" style={{marginLeft:"10px"}} onClick={() => generateSeats(containerIndex)}>Generate Seats</button>

              {/* Generated Seats Preview */}
              <h4>Generated Seats</h4>
              {container.rows.map((row, rowIndex) => (
                <div key={rowIndex}>
                  <strong>Rows:</strong> {row.seats.join(', ')}
                </div>
              ))}
            </div>
          ))}

          {/* Add Container Button */}
          <button type="button" className="btn btn-primary" onClick={addContainer}>Add Container</button>

          {/* Submit Button */}
          <button type="submit" className="submit-button">Submit</button>
        </form>

        {/* Overall Preview (Optional) */}
        <h2>Overall Preview:</h2>
        {containers.map((container, containerIndex) => (
          <div key={containerIndex}>
            <h3>Container {containerIndex + 1}: {container.container_name}</h3>
            {container.rows.map((row, rowIndex) => (
              <div key={rowIndex}>
                <strong>Rows:</strong> {row.seats.join(', ')}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CreateScreen;
