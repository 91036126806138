import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateMovies(props) {
  const [movie_name, setMovieName] = useState(""); // Movie name
  const [description, setDescription] = useState(""); // Movie description
  const [movie_image, setMovieImage] = useState(null); // Movie image
  const [category, setCategory] = useState(""); // Movie category
  const [ratings, setRatings] = useState(""); // Movie ratings
  const [languageList, setLanguageList] = useState([]); // List of languages for dropdown
  const [genreList, setgenreList] = useState([]);
  const [language, setLanguage] = useState(""); // Selected language
  const [timing, setTiming] = useState(""); // Movie timing
  const [movieTypeList, setMovieTypeList] = useState([]); // List of movie types
  const [movie_type, setMovieType] = useState(""); // Selected movie type
  const [release_date, setReleaseDate] = useState(""); // Movie release date
  const [youtube_link, setYoutubeLink] = useState(""); // Youtube trailer link
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const api_url = localStorage.getItem('api_url');

  // Fetch language and movie type options
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const languageResponse = await axios.get(api_url + '/getlang');
        setLanguageList(languageResponse.data.language);

        const languageResponse1 = await axios.get(api_url + '/getCategories');
        setgenreList(languageResponse1.data.categories);

        const movieTypeResponse = await axios.get(api_url + '/getmovietypes');
        setMovieTypeList(movieTypeResponse.data.movietypes);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
    fetchOptions();
  }, [api_url]);

  // Validation function
  const validateInputs = () => {
    const errors = {};
    if (!movie_name) errors.movie_name = "Movie name is required";
    if (!description) errors.description = "Description is required";
    if (!category) errors.category = "Genre is required";
    if (!ratings) errors.ratings = "Ratings are required";
    if (!language) errors.language = "Language selection is required";
    if (!timing) errors.timing = "Timing is required";
    if (!movie_type) errors.movie_type = "Movie type selection is required";
    if (!release_date) errors.release_date = "Release date is required";
    if (!movie_image) errors.movie_image = "Movie image is required";
    return errors;
  };

  // Handle form submission
  const addMovie = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("movie_name", movie_name);
    formData.append("description", description);
    formData.append("movie_image", movie_image);
    formData.append("category", category);
    formData.append("ratings", ratings);
    formData.append("language", language);
    formData.append("timing", timing);
    formData.append("movie_type", movie_type);
    formData.append("release_date", release_date);
    formData.append("youtube_link", youtube_link);

    try {
      const response = await axios.post(api_url + '/addMovie', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.status) {
        alert(response.data.message);
        navigate("/Movies", { replace: true });
      }
    } catch (error) {
      console.error("Error adding movie:", error);
      alert("Failed to add movie.");
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setMovieImage(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Add Movie Details</p>
          <button className="itme_img11" onClick={addMovie}>Save</button>
        </div>
        <hr />
        <div className="product_detail">
          {/* Movie Name */}
          <div className="item_details">
            <p className="item_name">Movie Name</p>
            <input
              type="text"
              className="item_input_cat"
              value={movie_name}
              onChange={(e) => setMovieName(e.target.value)}
            />
            {errors.movie_name && <Alert severity="error">{errors.movie_name}</Alert>}
          </div>
          <br />
          {/* Description */}
          <div className="item_details">
            <p className="item_name">Description</p>
            <textarea
              className="item_input_cat"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errors.description && <Alert severity="error">{errors.description}</Alert>}
          </div>
          <br />
          {/* Category */}
          <div className="item_details">
            <p className="item_name">Genre</p>
            <select
              className="item_input_cat"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select a genre</option>
              {genreList.map(type => (
                <option key={type.cat_id} value={type.cat_id}>
                  {type.cat_name}
                </option>
              ))}
            </select>
            {errors.category && <Alert severity="error">{errors.category}</Alert>}
          </div>
          <br />
          {/* Ratings */}
          <div className="item_details">
            <p className="item_name">Ratings</p>
            <input
              type="text"
              className="item_input_cat"
              value={ratings}
              onChange={(e) => setRatings(e.target.value)}
            />
            {errors.ratings && <Alert severity="error">{errors.ratings}</Alert>}
          </div>
          <br />
          {/* Language */}
          <div className="item_details">
            <p className="item_name">Language</p>
            <select
              className="item_input_cat"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="">Select a language</option>
              {languageList.map(lang => (
                <option key={lang.cat_id} value={lang.cat_id}>
                  {lang.language}
                </option>
              ))}
            </select>
            {errors.language && <Alert severity="error">{errors.language}</Alert>}
          </div>
          <br />
          {/* Timing */}
          <div className="item_details">
            <p className="item_name">Timing</p>
            <input
              type="text"
              className="item_input_cat"
              value={timing}
              onChange={(e) => setTiming(e.target.value)}
            />
            {errors.timing && <Alert severity="error">{errors.timing}</Alert>}
          </div>
          <br />
          {/* Movie Type */}
          <div className="item_details">
            <p className="item_name">Movie Type</p>
            <select
              className="item_input_cat"
              value={movie_type}
              onChange={(e) => setMovieType(e.target.value)}
            >
              <option value="">Select a movie type</option>
              {movieTypeList.map(type => (
                <option key={type._id} value={type._id}>
                  {type.movie_type}
                </option>
              ))}
            </select>
            {errors.movie_type && <Alert severity="error">{errors.movie_type}</Alert>}
          </div>
          <br />
          {/* Release Date */}
          <div className="item_details">
            <p className="item_name">Release Date</p>
            <input
              type="date"
              className="item_input_cat"
              value={release_date}
              onChange={(e) => setReleaseDate(e.target.value)}
            />
            {errors.release_date && <Alert severity="error">{errors.release_date}</Alert>}
          </div>
          <br />
          {/* YouTube Link */}
          <div className="item_details">
            <p className="item_name">YouTube Trailer Link</p>
            <input
              type="text"
              className="item_input_cat"
              value={youtube_link}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
          </div>
          <br />
          {/* Movie Image */}
          <div className="item_details">
            <p className="item_name">Movie Image</p>
            <input type="file" onChange={handleFileChange} />
            {errors.movie_image && <Alert severity="error">{errors.movie_image}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateMovies);
