import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link  } from 'react-router-dom';
import {withRouter} from "../components/withRouter"
import '../assets/css/style.css';
import axios from 'axios';

class Login extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',password:''
    };     
    this.loginfun = this.loginfun.bind(this); 
    this.onChange_email = this.onChange_email.bind(this);    
    this.onChange_password = this.onChange_password.bind(this);    
    this.signuppage = this.signuppage.bind(this);       
 
  }

  signuppage() {
    this.props.navigate('/Signup');
  }
  onChange_email(e){    
    this.setState({
        email:e.target.value
    }); 
  }
  onChange_password(e){    
    this.setState({
        password:e.target.value
    }); 
  }
  loginfun(e){
    var signin = new FormData();
    signin.set('email', this.state.email);
    signin.set('password', this.state.password);     
    
    axios({
      method: 'post',
      url: this.state.api_url+'/adminlogin',
      data: {'email':this.state.email,'password':this.state.password} 
        
            })
    .then(res => {
     
      if(res.data.status == "false"){
        let errors = {};
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'errorMsghide'
                });
              }, 3000);  

      }else{

        if(!res.data.admin_id){

          let errors = {};
          errors["message"] = "Incorrect Username and Password";
          this.setState({
              errors: errors,
              suc_cls: 'errorMsg floatingAlerts1'
              });
              setTimeout(() => {
                  this.setState({
                      suc_cls: 'errorMsghide'
                  });
                }, 3000);  
          
        }else{
          localStorage.setItem("admin_id",res.data.admin_id.id)
          localStorage.setItem("admin_email", res.data.admin_id.id);
          this.props.navigate('/Dashboard');
        }
        
      }
    })
   
  }
  render() {
    return <div className="login">
    <div className="div">
      <div className="group">
        <p className="text-wrapper">Sign in to your account</p>
        <p className="p">
        <div className={this.state.suc_cls}>{this.state.errors.message}</div>
          Thank you get back to Flick On Click, Sign in with your email and password if you have a Admin account.
        </p>        
        <div className="group-2">
          <div className="input-filled">
            <div className="overlap1">
            <input className="input-BG" id="password" value={this.state.password} onChange={this.onChange_password} type="password" maxLength={15} minLength={6} placeholder="Password" required />										
              <div className="feather-icon-eye-off">
                <div className="shape">
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap1">
            <input required className="input-BG" type="email" id="email" placeholder="Email" value={this.state.email} onChange={this.onChange_email} />
            </div>
          </div>
          <div className="primary1">            
             <button  onClick={this.loginfun}  className="continue" style={{border: "none",background:"#25388D", color:"#ffffff"}}>  
             Sign In           
            </button> 
          </div>
        </div>
      </div>
      <img  src={require('../assets/img/LogoT.png')} className="frame" alt="Frame"  />
    </div>
  </div>;
  }
}

export default withRouter(Login);



