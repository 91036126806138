import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import SidebarOne from "./sidebar1"; // Assuming SidebarOne is a valid component
import { withRouter } from "./withRouter"; // If using react-router v6, consider using hooks instead

function CreateTheatre(props) {
  const [theatreName, setTheatreName] = useState("");
  const [theatreImage, setTheatreImage] = useState(null);
  const [phoneno, setPhoneno] = useState("");
  const [email, setEmail] = useState("");
  const [place, setPlace] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [lat, setLat] = useState("");
  const [facilities, setFacilities] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  
  const navigate = useNavigate();
  
  const api_url = localStorage.getItem('api_url');

  // Validation function
  const validateInputs = () => {
    const errors = {};

    if (!theatreName.trim()) {
      errors.theatreName = "Theatre name is required";
    }

    if (!phoneno.trim()) {
      errors.phoneno = "Phone number is required";
    } else if (!/^\d{10}$/.test(phoneno)) {
      errors.phoneno = "Phone number must be 10 digits";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!place.trim()) {
      errors.place = "Place is required";
    }

    if (!addressDetail.trim()) {
      errors.addressDetail = "Address details are required";
    }

    if (!lat.trim()) {
      errors.lat = "Latitude is required";
    } else if (isNaN(lat)) {
      errors.lat = "Latitude must be a number";
    }

    if (!facilities.trim()) {
      errors.facilities = "Facilities are required";
    }

    if (!password.trim()) {
      errors.password = "Password is required";
    }


    if (!theatreImage) {
      errors.theatreImage = "Theatre image is required";
    } else {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(theatreImage.type)) {
        errors.theatreImage = "Only JPEG, PNG, and JPG images are allowed";
      }
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (theatreImage.size > maxSize) {
        errors.theatreImage = "Image size should be less than 5MB";
      }
    }

    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("theatre_name", theatreName);
    formData.append("phoneno", phoneno);
    formData.append("email", email);
    formData.append("place", place);
    formData.append("address_detail", addressDetail);
    formData.append("lat", lat);
    formData.append("facilities", facilities);
    formData.append("password", password);
    // formData.append("rawpassword", rawpassword);
    formData.append("theatre_image", theatreImage);

    try {
      const response = await axios.post(`${api_url}/addtheatredetails`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        alert("Theatre added successfully!");
        navigate("/Theatres", { replace: true });
        window.location.reload();
      } else {
        alert("Failed to add theatre. Please try again.");
      }
    } catch (error) {
      console.error("Error adding theatre:", error);
      alert("An error occurred while adding the theatre.");
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setTheatreImage(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p className="p_name">Create Theatre</p>
        </div>

        <hr />

        <form className="product_detail" >
          {/* Theatre Name */}
          <div className="item_details">
            <label className="item_name">Theatre Name</label>
            <input
              type="text"
              className="item_input_cat"
              value={theatreName}
              onChange={(e) => setTheatreName(e.target.value)}
            />
            {errors.theatreName && <Alert severity="error">{errors.theatreName}</Alert>}
          </div>
          <br />

          {/* Phone Number */}
          <div className="item_details">
            <label className="item_name">Phone Number</label>
            <input
              type="text"
              className="item_input_cat"
              value={phoneno}
              onChange={(e) => setPhoneno(e.target.value)}
              maxLength="10"
              placeholder="Enter 10-digit phone number"
            />
            {errors.phoneno && <Alert severity="error">{errors.phoneno}</Alert>}
          </div>
          <br />

          {/* Email */}
          <div className="item_details">
            <label className="item_name">Email</label>
            <input
              type="email"
              className="item_input_cat"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter valid email"
            />
            {errors.email && <Alert severity="error">{errors.email}</Alert>}
          </div>
          <br />

          {/* Place */}
          <div className="item_details">
            <label className="item_name">Place</label>
            <input
              type="text"
              className="item_input_cat"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              placeholder="Enter place"
            />
            {errors.place && <Alert severity="error">{errors.place}</Alert>}
          </div>
          <br />

          {/* Address Detail */}
          <div className="item_details">
            <label className="item_name">Address Detail</label>
            <textarea
              className="item_input_cat1"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
              placeholder="Enter detailed address"
            />
            {errors.addressDetail && <Alert severity="error">{errors.addressDetail}</Alert>}
          </div>
          <br />

          {/* Latitude */}
          <div className="item_details">
            <label className="item_name">Latitude</label>
            <input
              type="text"
              className="item_input_cat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              placeholder="Enter latitude"
            />
            {errors.lat && <Alert severity="error">{errors.lat}</Alert>}
          </div>
          <br />

          {/* Facilities */}
          <div className="item_details">
            <label className="item_name">Facilities</label>
            <input
              type="text"
              className="item_input_cat"
              value={facilities}
              onChange={(e) => setFacilities(e.target.value)}
              placeholder="e.g., Parking, 3D, Dolby Atmos"
            />
            {errors.facilities && <Alert severity="error">{errors.facilities}</Alert>}
          </div>
          <br />

          {/* Password */}
          <div className="item_details">
            <label className="item_name">Password</label>
            <input
              type="password"
              className="item_input_cat"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
            />
            {errors.password && <Alert severity="error">{errors.password}</Alert>}
          </div>
          <br />

         

          {/* Theatre Image */}
          <div className="item_details">
            <label className="item_name">Theatre Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            {errors.theatreImage && <Alert severity="error">{errors.theatreImage}</Alert>}
          </div>
          <br />

          {/* Submit Button */}
          <div className="item_details" style={{ textAlign: 'right' }}>
            <button type="button" className="itme_img11" onClick={handleSubmit}>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withRouter(CreateTheatre);
