import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";

function CreateMovieType(props) {
  const [movie_type, setMovieType] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!movie_type) {
      errors.movie_type = "Movie type is required";
    }
    return errors;
  };

  const addMovieTypeDetails = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const admin_id = localStorage.getItem('admin_id');

    const api_url = localStorage.getItem('api_url');
        const response = await axios.post(api_url + '/addmovietype', {
         movie_type
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status) {
          
          alert("Movie type added successfully");
          navigate("/MovieType", { replace: true });
          window.location.reload();
        }

  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Movie Types </p>
        </div>

        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Movie Type</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={movie_type}
              onChange={(e) => setMovieType(e.target.value)}
            />
            {errors.movie_type && <Alert severity="error">{errors.movie_type}</Alert>}
          </div>
        </div>

        <div className="item_details" style={{ textAlign: 'right' }}>
            <button type="button" className="itme_img11" onClick={addMovieTypeDetails}>Save</button>
          </div>
      </div>
    </div>
  );
}

export default withRouter(CreateMovieType);
