import React from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";

class viewAddon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      product: {},
      productattribute: {},
      productspec: [],
      cat_image:{},
      id: localStorage.getItem("id"),
      showAlert: false,
      errors: {},
      suc_cls: '',
      mediaVisible: false,
      specsVisible: false,
      hierarchyVisible: false,
      attributesVisible: false
    };

  }
  toggleVisibility = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }));
  };


  componentDidMount() {
    const { api_url, id } = this.state;
    axios({
      method: 'get',
      url: `${api_url}/getaddon/${id}`,
    }).then(res => {
      const data = res.data;
      this.setState({
        product: data.addon
      });

      // this.setState({
      //   brand_image: data.brand_image
      // });
    });
  }


  moveeditproduct = () => {
    this.props.navigate("/editAddon");
  };

  render() {
    const { product } = this.state;

    return (
      <div className="view_pro" style={{'display':'flex'}}>
        <div>
        <SidebarOne />
        </div>
        
      <div className="pr_names">
        <div className="pro_names" style={{'display':'flex'}}> 
            <p className="p_name">Addons </p>
            <p className="act_name" style={{'textAlign':'left'}}>Active</p>
        </div>

        <hr></hr>
      <div className="product_detail">
      

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Addon Name</p>
                  <div className="img_detils_cat">
                    <img className="itme_img" title="edit Group" onClick={this.moveeditproduct} src={require("../assets/images/edit.png")} />
                  </div>
               </div>
                <input readOnly type="text" className="item_input_cat" name="item_id" value={product.addon_name} />
            </div>
            <br></br>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Weight</p>
               </div>
               <input readOnly type="text" className="item_input_cat" name="item_id" value={product.weight} />
            </div>

            <div className="item_details">
              <div style={{'display':'flex'}}>
                <p className="item_name">Price</p>
               </div>
               <input readOnly type="text" className="item_input_cat" name="item_id" value={product.price} />
            </div>

           
           
      
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(viewAddon);
