import React from 'react';
import {withRouter} from "./withRouter"


class InnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      admin_id: localStorage.getItem('admin_id'),
      data: [],
      data1: [],
      data2: [],
      data3: []
    };
  }

  componentDidMount() {
    // Any data fetching or operations can go here
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.navigate('/Login');
    };

  render() {
    return (
      <div className="innerHeader">
        <div className=''>
          <img
            className="logout"
            alt="Logout"
            title="Logout"
            src={require('../assets/img/logout.png')}
            onClick={this.handleLogout}  // Use 'this' to refer to class methods
            style={{ cursor: 'pointer',marginLeft:"95%",marginTop:"20px",width:"40px" }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(InnerHeader);
