import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateHallseats(props) {
  const [hall_id, setHallId] = useState(""); // Updated for hall_id
  const [container_name, setContainerName] = useState(""); // Updated for container_name
  const [seat_numbers, setSeatNumbers] = useState(""); // Updated for seat_numbers
  const [price, setPrice] = useState(""); // For price field
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
   
    if (!container_name) {
      errors.container_name = "Container name is required";
    }
    if (!seat_numbers) {
      errors.seat_numbers = "Seat numbers are required";
    }
    if (!price) {
      errors.price = "Price is required";
    }
    return errors;
  };

  const addHallSeatDetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const hall_id = localStorage.getItem('hall_id'); // Assuming hall_id is stored in localStorage

    // Create a URL-encoded string
    const data = new URLSearchParams();
    data.append("hall_id", hall_id); // append hall_id
    data.append("container_name", container_name); // append container_name
    data.append("seat_numbers", seat_numbers); // append seat_numbers
    data.append("price", price); // append price

    axios({
      method: "post",
      url: api_url + "/addHallSeats", // Adjust your API endpoint
      data: data.toString(), // Use URL-encoded string
      headers: { 
        "Content-Type": "application/x-www-form-urlencoded" // Set content-type to x-www-form-urlencoded
      }
    })
      .then((res) => {
        if (res.data.status === "true") {
          alert(res.data.message);
          navigate("/HallSeats", { replace: true }); // Redirect after success
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding hall seats:", error);
        alert("Failed to add hall seats.");
      });
  };

  useEffect(() => {
    const fetchCards = async () => {
      // Any initial fetching logic can go here if needed
    };
    fetchCards();
  }, []); 

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Create Hall Seats</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr></hr>
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Block/Container Name</p>
              <button className="itme_img11" onClick={addHallSeatDetails}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={container_name}
              onChange={(e) => setContainerName(e.target.value)}
            />
            {errors.container_name && <Alert severity="error">{errors.container_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Available Seat Counts</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={seat_numbers}
              onChange={(e) => setSeatNumbers(e.target.value)}
            />
            {errors.seat_numbers && <Alert severity="error">{errors.seat_numbers}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Price per Seat</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            {errors.price && <Alert severity="error">{errors.price}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateHallseats);
