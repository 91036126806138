import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SidebarOne from "./sidebar1";
import Alert from '@mui/material/Alert';

function CreateHalls() {
  const [hall_name, setHallName] = useState("");
  const [hall_image, setHallImage] = useState(null); // For file upload
  const [phoneno, setPhoneno] = useState("");
  const [email, setEmail] = useState("");
  const [chair_price, setChairPrice] = useState("");
  const [stand_price, setStandPrice] = useState("");
  const [place, setPlace] = useState("");
  const [address_detail, setAddressDetail] = useState("");
  const [lat, setLat] = useState("");
  const [type, setType] = useState(""); // New state for hall type

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!hall_name) {
      errors.hall_name = "Hall name is required";
    }
    if (!phoneno) {
      errors.phoneno = "Phone number is required";
    }
    if (!email) {
      errors.email = "Email is required";
    }
    if (!type) {
      errors.type = "Hall type is required";
    }
    return errors;
  };

  const addHallDetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("hall_name", hall_name);
    if (hall_image) {
      formData.append("hall_image", hall_image);
    }
        formData.append("phoneno", phoneno);
    formData.append("email", email);
    formData.append("chair_price", chair_price);
    // formData.append("stand_price", stand_price);
    formData.append("place", place);
    formData.append("address_detail", address_detail);
    formData.append("lat", lat);
    formData.append("type", type); // Append the hall type


    axios({
      method: "post",
      url: api_url + "/addHall",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((res) => {
        if (res.data.status === "true") {
          alert(res.data.message);
          navigate("/Halls", { replace: true });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding hall:", error);
        alert("Failed to add hall.");
      });
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <SidebarOne />
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Add Hall</p>
        </div>
        <hr />
        <div className="product_detail">
          {/* Hall Name */}
          <div className="item_details">
            <p className="item_name">Hall Name</p>
            <input
              type="text"
              className="item_input_cat"
              value={hall_name}
              onChange={(e) => setHallName(e.target.value)}
            />
            {errors.hall_name && <Alert severity="error">{errors.hall_name}</Alert>}
          </div>

        {/* Hall Type */}
<div className="item_details">
  <p className="item_name">Hall Type</p>
  <select
    className="item_input_cat"
    value={type}
    onChange={(e) => setType(e.target.value)}
  >
    <option value="">Select Hall Type</option>
    <option value="event">Event</option>
    <option value="sport">Sports</option>
  </select>
  {errors.type && <Alert severity="error">{errors.type}</Alert>}
</div>


          {/* Hall Image */}
          <div className="item_details">
            <p className="item_name">Hall Image</p>
            <input
              type="file"
              className="item_input_cat"
              onChange={(e) => setHallImage(e.target.files[0])}
            />
          </div>

          {/* Phone Number */}
          <div className="item_details">
            <p className="item_name">Phone Number</p>
            <input
              type="text"
              className="item_input_cat"
              value={phoneno}
              onChange={(e) => setPhoneno(e.target.value)}
            />
            {errors.phoneno && <Alert severity="error">{errors.phoneno}</Alert>}
          </div>

          {/* Email */}
          <div className="item_details">
            <p className="item_name">Email</p>
            <input
              type="email"
              className="item_input_cat"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <Alert severity="error">{errors.email}</Alert>}
          </div>

          {/* Chair Price */}
          <div className="item_details">
            <p className="item_name">Price Start onwards</p>
            <input
              type="text"
              className="item_input_cat"
              value={chair_price}
              onChange={(e) => setChairPrice(e.target.value)}
            />
          </div>

          {/* Stand Price */}
          {/* <div className="item_details">
            <p className="item_name">Stand Price</p>
            <input
              type="text"
              className="item_input_cat"
              value={stand_price}
              onChange={(e) => setStandPrice(e.target.value)}
            />
          </div> */}

          {/* Place */}
          <div className="item_details">
            <p className="item_name">Place</p>
            <input
              type="text"
              className="item_input_cat"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />
          </div>

          {/* Address Detail */}
          <div className="item_details">
            <p className="item_name">Address Detail</p>
            <input
              type="text"
              className="item_input_cat"
              value={address_detail}
              onChange={(e) => setAddressDetail(e.target.value)}
            />
          </div>

          {/* Latitude */}
          <div className="item_details">
            <p className="item_name">Latitude</p>
            <input
              type="text"
              className="item_input_cat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
          </div>

          <button className="itme_img11" onClick={addHallDetails}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default CreateHalls;
