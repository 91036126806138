import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateAddon(props) {
  const [addon_name, setAddonName] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!addon_name) {
      errors.addon_name = "Addon name is required";
    }
    return errors;
  };

  const addproductdetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("addon_name", addon_name);
    formData.append("weight", weight);
    formData.append("price", price);

    axios({
      method: "post",
      url: api_url + "/addAddon",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((res) => {
        if (res.data.status === "true") {
          alert(res.data.message);
          navigate("/Addon", { replace: true });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding addon:", error);
        alert("Failed to add addon.");
      });
  };

  useEffect(() => {
    const fetchCards = async () => {
      // Any initial fetching logic can go here if needed
    };
    fetchCards();
  }, []); 

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Addons </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr></hr>
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Addon Name</p>
              <button className="itme_img11" onClick={addproductdetails}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={addon_name}
              onChange={(e) => setAddonName(e.target.value)}
            />
            {errors.addon_name && <Alert severity="error">{errors.addon_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Weight</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Price</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateAddon);
